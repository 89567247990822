export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CLEAR = "CLEAR";
export const SET_USER = "SET_USER";
export const FETCH_USER = "FETCH_USER";
export const SHOW_LOG_IN = "SHOW_LOG_IN";
export const HIDE_LOG_IN = "HIDE_LOG_IN";
export const HANDLE_LOGOUT = "HANDLE_LOGOUT";
export const TOGGLE_MAINTENANCE = "TOGGLE_MAINTENANCE";
export const SET_DYNAMIC_STRINGS = "SET_DYNAMIC_STRINGS";

export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  data: { language }
});

export const clearStore = () => ({
  type: CLEAR,
  data: null
});

export const fetchUser = () => ({
  type: FETCH_USER,
});

export const setUser = (user) => ({
  type: SET_USER,
  data: { user }
});

export const showLogIn = () => ({
  type: SHOW_LOG_IN,
  data: {
    displayLogIn: true
  }
});

export const hideLogIn = () => ({
  type: HIDE_LOG_IN,
  data: {
    displayLogIn: false
  }
});

export const handleLogout = () => ({
  type: HANDLE_LOGOUT
});

export const toggleMaintenance = (isActive, message) => ({
  type: TOGGLE_MAINTENANCE,
  data: { isActive, message }
});

export const setDynamicStrings = (dynamicStrings) => {
  return {
    type: SET_DYNAMIC_STRINGS,
    data: dynamicStrings
  }
};
