import React from "react";
import { useSelector } from 'react-redux';


const ExternalLinkItem: React.FunctionComponent<any> = ({ url, text, className, image, alt, style, target = "_blank" , studentBypass, children}) => {
  // @ts-ignore
  const user = useSelector(store => store.user);
  const isStudent = user && user.data && user.institutionName
  if (false) {
  // if (isStudent && !studentBypass) {
    return <a href={"/leaving_student_access?redirectUrl=" + url} target={target} style={style} rel="noreferrer" className={className}>{image && <img src={image} alt={alt} />}{text || children}</a>
  } else {
    return <a href={url} target={target} rel="noreferrer"  style={style}  className={className}>{image && <img src={image} alt={alt} />}{text || children}</a>
  }
};

export default ExternalLinkItem;
