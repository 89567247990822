import React from "react";
import Hero from "../components/Hero/Hero";
import Grid from "../components/Grid/Grid";
import VideoAndAudioCatalog from "../components/VideoAndAudioCatalog/VideoAndAudioCatalog";
import Editorial from "components/Editorial/Editorial";
import { childrenTileMapper } from "../utils";

enum Component {
  Hero,
  Grid,
  MiniGrid,
  Filter,
  Editorial
};

const getComponentProps = (components, language, pageId) => {
  return components.map((component, index) => {
    switch (component.type.toLowerCase()) {
      case "hero":
        return {
          type: Component.Hero,
          componentType: component.type,
          slides: component.collection.items.nodes.map(item => {
            const mediaType = item.mediaType && item.mediaType.toLowerCase();
            const videoId = item.extId;
            const type = item.contentType && item.contentType.toLowerCase();
            const header = item.slide.header;
            const subHeader = item.slide.subHeader;
            const description = item.slide.description;
            const image = item.slide.image.url;
            const ctaLabel = item.slide.ctaLabel;
            const url = type === "broadcast" ? (item.broadcastType === "AUDIO_BROADCAST" ? `audio_broadcast/${item.id}` : `broadcast/${item.id}`) : `performance/detail/${item.id}`;

            return {
              mediaType,
              videoId,
              type,
              header,
              subHeader,
              description,
              image,
              ctaLabel,
              url
            }
          })
        }

      case "grid":
      case "carousel":
      case "watchhistory":
      case "favorites":
        return {
          type: component.type.toLowerCase() === "grid" ? Component.Grid : Component.MiniGrid,
          componentType: component.type,
          header: component.header,
          tiles: (component.collection && component.collection.items && component.collection.items.nodes && component.collection.items.nodes.length !== 0) ?
            childrenTileMapper(component.collection.items.nodes) : []
        }

      case "filter":
        return {
          type: Component.Filter,
          componentType: component.type,
          header: component.header
        }

      case "editorial":
        return {
          type: Component.Editorial,
          componentType: component.type,
          html: component.html
        }

      default:
        return null;
    }
    }).filter(props => {
      if(props == null)
        return false;

      switch (props.type) {
        case Component.Hero:
          return props.slides.length;

        case Component.Grid:
        case Component.MiniGrid:
          return props.tiles.length;

        default:
          return true;
      }
    })
}

const getComponents = (components, language, pageId, options?) => {
  return getComponentProps(components, language, pageId).map((fullProps, index) => {
    const { type, ...props } = fullProps;
    let component;
    switch (type) {
      case Component.Hero:
        component = <Hero key={index} {...props} />;
        break;
      case Component.Grid:
      case Component.MiniGrid:
        component =  <Grid key={index} {...props} showPagination={type === Component.MiniGrid ? true : false} expandGrid={options && options.expandGrid} />;
        break;
      case Component.Filter:
        component = <VideoAndAudioCatalog key={index} {...props} />;
        break;
      case Component.Editorial:
        component =  <Editorial key={index} {...props} />;
        break;
    }
    return component;
  })
}

const getPageInfo = (screen, language) => {
  return {
    title: screen.header || process.env.REACT_APP_PAGE_TITLE,
    id: screen.id
  }
};

export default function parse(screen, language, options) {
  const components = (screen && screen.components) || [];
  const pageId = (screen && screen.id) || "";
  return {
    ...getPageInfo(screen, language),
    componentProps: getComponentProps(components, language, pageId),
    components: getComponents(components, language, pageId, options)

  }
};

export { Component };
