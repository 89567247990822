import React from "react";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { withRouter } from "react-router-dom";

interface FetchProps {
  url?: string;
  method: string;
  body?: any;
  query?: any;
  options?: any;
  children: (loading?: boolean, errors?: any[], data?: any, status?: number, refetch?: Function) => React.ReactNode;
  toggleMaintenance: (isActive: boolean, message?: string) => void;
  addMiddlewareHeaders?: boolean;
  store: any;
  location: any;
  authzBeforeRequest?: boolean;
};

class Fetch extends React.Component<FetchProps, any> {
  static defaultProps = {
    method: "GET"
  }

  constructor(props) {
    super(props);
    this.state = {
      authzStatus: -1,
      loading: false,
      errors: undefined,
      data: undefined,
      status: undefined
    };
  }

  componentDidMount() {
    this.performFetch();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.url !== this.props.url) {
      this.performFetch();
    }
  }

  getQueryUrl = () => {
    const { url, query } = this.props;
    let queryVars: string[] = [];

    if(!url)
      return;

    for(let key in query) {
      queryVars.push(`${key}=${query[key]}`);
    }
    return `${url}?${queryVars.join("&")}`;
  }

  performFetch = () => {
    const { url, method, body, query, options, toggleMaintenance, addMiddlewareHeaders } = this.props;
    let resolvedUrl = (query && this.getQueryUrl()) || url;
    let params: any = { method, headers: {}, ...options };

    if(addMiddlewareHeaders) {
      params.headers = {
        ...params.headers,
        "origin-hostname": window.location.hostname,
        "user-platform": "Web",
        "Cache-Control": "no-store"
      }
    }

    if(!resolvedUrl) {
      this.setState({ loading: false });
      return;
    }


    if(method === "POST") {
      params.headers["Content-Type"] = "application/json"
      if(body) params.body = JSON.stringify(body);
    }

    this.setState({ loading: true });

    fetch(resolvedUrl!, params)
    .then(async response => {
      let status = response.status;
      let loading = false;
      let contentType = response.headers.get('content-type');
      let data;

      if(contentType && contentType.includes('application/json')) {
        data = await response.json();
        if(response.ok) {
          this.setState({ loading, data, status });
        }
        else if(response.status === 503) {
          toggleMaintenance(true, data.message);
        } else {
          this.setState({
            loading,
            ...data, // contains `data` and `errors` fields
            status
          })
        }
      }
      else {
        data = await response.text();
        if(response.ok) {
          this.setState({ loading, data, status });
        }
        else {
          this.setState({
            loading,
            status,
            errors: [{ message: data }]
          })
        }
      }
    })
    .catch(err => {
      this.setState({
        loading: false,
        errors: [{ message: err.message }]
      })
    })
  }

  render() {
    const { loading, errors, data, status } = this.state;
    const { children } = this.props;
    return children(loading, errors, data, status, this.performFetch);
  }
}

export default compose<any, any, any>(withRouter, withStore)(Fetch);
