import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { SVG } from "../../assets/images";
import { getClientApiURL } from "../../utils"
import "./SubscribersOptions.scss";


class SubscribersOptions extends React.Component<any,any> {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.onClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClick);
  }

  onClick = (e) => {
    if (!e.target.closest(".SubscribersOptions-container")) {
      document.querySelectorAll(".SubscribersOptions").forEach((el) => {
        el.classList.add("option-list-hidden");
      });
    }
  }

  onLogOut = () => {
    const logout = fetch(`${getClientApiURL("/logout")}`, {credentials: "include"})
    logout.then(response => {
      if(response.status === 200) {
        this.props.handleLogout();
      }
    });
  }

  onArrowClick = () => {
    document.querySelectorAll(".SubscribersOptions").forEach((el) => {
      el.classList.toggle("option-list-hidden");
    });
  }

  render () {
    const { store: { user, userFetchPending } } = this.props;
    const isStudent = user.data && user.institutionName;
    const subscriberStuff = <ul className="SubscribersOptions-options-list">
      <li><a href="https://www.metopera.org/account/manage-on-demand-subscription/">Your Subscription</a></li>
      <li><a href="https://www.metopera.org/season/on-demand/met-opera-on-demand-faq/">Help/FAQs</a></li>
      <li><a href="https://www.metopera.org/season/on-demand/gift-subscriptions/">Gift Subscriptions</a></li>
      <li><span onClick={this.onLogOut}>Logout</span></li>
    </ul>
    const salutation = isStudent ? "" : "Hi, "
    const SubscriberOptionsTitleClass = `SubscribersOptions-title ${isStudent ? "cursor-default" : ""}`
    return (
      <div className="SubscribersOptions option-list-hidden">
        {
          !userFetchPending && user &&
          <div className="SubscribersOptions-container">
            <span className={SubscriberOptionsTitleClass} onClick={isStudent ? undefined : this.onArrowClick}>{salutation}{(user.data && user.institutionName )|| (user.data && user.data.LoginInfo.salutation)}
              {!isStudent ? <SVG.ArrowDown className="SubscribersOptions-arrow" /> : null}
              {!isStudent ? subscriberStuff : null}
            </span>
          </div>
        }
      </div>
    )
  }
}

export default compose(withRouter, withStore)(SubscribersOptions);
