import React from "react";
import { compose } from "react-apollo";
import { withStore } from "../../store";
import ExternalLink from "../ExternalLink/ExternalLink";
import { Image } from "../../assets/images";
import { Button } from "@brightcove/studio-components";
import "./AppDownloads.scss";

const LinkItem: React.FunctionComponent<any> = ({ url, text, image, alt, className, studentBypass }) => {
  return <li><ExternalLink url={url} text={text} image={image} alt={alt} className={className} studentBypass={studentBypass} /></li>
};

const AppDownloads: React.FunctionComponent<any> = ({store}) => {
  const user = store.user;
  const dynamicStrings = store.dynamicStrings;
  const isStudent = user && user.data && user.institutionName

  return (
    <div className="AppDownloads">
      <div className="AppDownloads-intro">
      {
        isStudent ?
        <div dangerouslySetInnerHTML={{__html: dynamicStrings.intro_text_student}} /> :
        <div dangerouslySetInnerHTML={{__html: dynamicStrings.intro_text}} />
      }
      </div>
      {
        !isStudent &&
        <div className="AppDownloads-apps-list">
          <p>
            <img src={Image.devicePoster} alt="Devices" />
          </p>
          <p>Download the free apps</p>
          {dynamicStrings &&
            <ul>
              <LinkItem url={dynamicStrings["iTunes_store_app_url"]} image={Image.appStoreLogo} studentBypass={true} alt="App Store Logo" />
              <LinkItem url={dynamicStrings["roku_channel_store_app_url"]} image={Image.roku} studentBypass={true} alt="Roku Logo" />
              <LinkItem url={dynamicStrings["google_play_store_app_url"]} image={Image.googlePlayStoreLogo} studentBypass={true} alt="Google Play Store Logo" />
              <LinkItem url={dynamicStrings["amazon_app_store_url"]} image={Image.amazonAppStoreLogo} studentBypass={true} alt="Amazon App Store Logo" />
              <LinkItem url={dynamicStrings["samsung_app_store_url"]} image={Image.samsung} studentBypass={true} alt="Samsung Logo" />
            </ul>
          }
          {!user && <p>Discover the ultimate opera streaming collection, and watch on your computer, tablet, mobile phone, or TV for $14.99/month or $149.99/year with our unlimited subscription plan. Met Members save 33% off annual subscription price.</p>}
        </div>
      }
      {!user &&
        <div className="AppDownloads-intro-ctas">
          <Button theme="classic" Component="a" componentProps={{href: "https://www.metopera.org/season/on-demand/Learn-More/", target: "_blank"}} text="Learn More" className="btn btn-pink" />
          <Button theme="classic" Component="a" componentProps={{href: "https://www.metopera.org/season/on-demand/met-opera-on-demand-faq/", target: "_blank"}} text="Read FAQs" className="btn btn-transparent black" />
        </div>
      }
      {
        isStudent &&
          <div>
            <h3>STUDENT TOOLS</h3>
            <ul className="student-tools">
                <LinkItem className="student-link" url="https://metopera.org/season/on-demand/mood-insights" text="MET OPERA ON DEMAND INSIGHTS" />
                <LinkItem className="student-link" url="https://www.metopera.org/season/on-demand/student-access/student-access-faqs/" text="FAQS-HELP" />
                <LinkItem  className="student-link" url="http://www.metopera.org/season/on-demand/student-access/student-access-contact-the-met/" text="CONTACT US"/>
            </ul>
          </div>
      }
    </div>
  )
}

export default compose(withStore)(AppDownloads);
