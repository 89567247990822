import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { withStore } from "../../store";
import BroadcastParser from "../../parser/BroadcastParser";
import Fetch from "../../components/Fetch/Fetch";
import Loading from "../../components/Loading/Loading";
import { getActiveItem, fetchHandler, getClientApiURL, liveAndUpcomingItems } from "../../utils";
import "./Broadcast.scss";

class Broadcast extends React.Component<any, any> {

  scheduleInterval;

  constructor(props) {
    super(props);
    this.state = {
      activeItem: undefined,
      components: this.props.components,
      tier: this.props.tier,
      player: null
    };
  }

  componentDidMount() {
    const items = this.getBroadcastSchedule();
    const activeItem = getActiveItem(items);
    if (activeItem) {
      this.setState({activeItem});
    }
    this.setActiveItem();
  }

  setActiveItem = () => {
    const items = this.getBroadcastSchedule();
    this.scheduleInterval = setInterval(() => {
      const activeItem = getActiveItem(items);

      if (
        this.state.activeItem
        && activeItem
        && this.state.activeItem.start !== activeItem.start
        // && (!this.state.player || this.state.player.isDisposed_ || this.state.player.ended())
      ) {
        this.setState({activeItem});
        this.getBroadCastInfo();
      }
    }, 1000);
  }

  getBroadcastSchedule = () => {
    const { components } = this.props;
    const schedule = components.filter(component => (component.type.toLowerCase() === "schedule"));
    return schedule.length > 0 && liveAndUpcomingItems(schedule[0].items);
  }

  getBroadCastInfo = () => {
    const broadcastUrl = getClientApiURL(`/broadcast/${this.props.id}`);
    fetchHandler(broadcastUrl, {}, (data) => {
      if(data.components) {
        this.setState({
          components: data.components
        });
      }
    });
  }

  onPlayerCreated = (player) => {
    this.setState({
      player
    });
  }

  componentWillUnmount() {
    this.scheduleInterval && clearInterval(this.scheduleInterval);
  }

  render() {
    const { activeItem, components, tier } = this.state;
    const { store: { dynamicStrings } } = this.props;
    const url = activeItem && getClientApiURL(`/performance/${activeItem.id}`);
    let screen = BroadcastParser({}, components, activeItem, this.onPlayerCreated, tier);
    const items = this.getBroadcastSchedule();

    return (
      (!activeItem && !items.length) ?
        <div className="Broadcast-content">
          <div className="no-broadcasts-message">{dynamicStrings["no_broadcasts_message"]}</div>
        </div> :
      url ?
        <Fetch
          url={url}
          addMiddlewareHeaders
          options={{ credentials: "include" }}
          authzBeforeRequest
          >
          {(loading, errors, data) => {
            screen = BroadcastParser(data || {}, components, activeItem, this.onPlayerCreated, tier);
            const isLastItem = items[items.length - 1].id === activeItem.id;

            return (
              data ?
              <div className={`Broadcast-content${isLastItem ? " broadcast-ending" : ""}`}>
                { loading && <Loading /> }
                { data && screen.components }
              </div> : ''
            )
          }}
        </Fetch> :
        <div className="Broadcast-content">
          {screen && screen.components}
        </div>
    )
  }
};

export default compose(withRouter, withStore)(Broadcast);
