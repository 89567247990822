import React from "react";
import { getAllRoles } from "../../utils";
import "./PersonInfo.scss";

const PersonInfo: React.FunctionComponent<any> = ({info}) => {
  const { name, roles, biography, imageUrl } = info;

  return (
    <div className="PersonInfo">
      <div className="person-image">
        {imageUrl && <img src={imageUrl} alt="Person" />}
      </div>
      <div className="person-info">
        <h2>{name}</h2>
        <p>{getAllRoles(roles)}</p>
        <p>{biography}</p>
      </div>
    </div>
  )
}

export default PersonInfo;
