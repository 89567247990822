import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import Tile from '../Tile/Tile';
import BroadcastTile from '../Tile/BroadcastTile';
import classnames from "classnames";
import { SVGImage } from "../../assets/images";
import { getPageType } from "../../utils";
import "./Carousel.scss";

const CarouselArow: React.FunctionComponent<any> = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

const settings = {
  slidesToShow: 4,
  slidesToScroll: 4,
  infinite: false,
  prevArrow: <CarouselArow><img src={SVGImage.SlickPrev} alt="Prev Button" /></CarouselArow>,
  nextArrow: <CarouselArow><img src={SVGImage.SlickNext} alt="Next Button" /></CarouselArow>,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        swipeToSlide: true,
        arrows: false,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        swipeToSlide: true,
        arrows: false,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        draggable: true
      }
    }
  ]
};

class Carousel extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      showCarousel: true
    }
  }

  toggleCarousel = () => {
    this.setState((prev) => {
      return {showCarousel: !prev.showCarousel};
    });
  }

  render() {
    const { showCarousel } = this.state;
    const { header, tiles, activeItem } = this.props;
    const componentType = this.props.componentType ? this.props.componentType.toLowerCase() : null;
    const isBroadcastPage = (getPageType() === "broadcast");
    return (
      <div className="Carousel">
        <div className="Carousel-header">
          <h2>{header}</h2>
          <span onClick={this.toggleCarousel}>{showCarousel ? "Hide" : "Show"}</span>
        </div>
        <Slider {...settings} className={classnames({ "hide": !showCarousel}, componentType)}>
          { tiles.map((tile, index) => {
              return (isBroadcastPage ? <BroadcastTile key={index} carouselLength={tiles.length} {...tile} activeItem={activeItem} /> : <Tile key={index} carouselLength={tiles.length} {...tile} />)
            })
          }
        </Slider>
      </div>
    )
  }
}

export default Carousel;
