import { changeLanguage, clearStore, setUser, fetchUser, showLogIn, hideLogIn, handleLogout, toggleMaintenance, setDynamicStrings } from "./actions";
import { connect } from 'react-redux';

const storePropsMapper = (state) => {
  return { store: state };
};

const storeActionsMapper = (dispatch) => {
  return {
    changeLanguage: (language) => dispatch(changeLanguage(language)),
    clearStore: () => dispatch(clearStore()),
    setUser: (user) => dispatch(setUser(user)),
    fetchUser: () => dispatch(fetchUser()),
    showLogIn: () => dispatch(showLogIn()),
    hideLogIn: () => dispatch(hideLogIn()),
    handleLogout: () => dispatch(handleLogout()),
    toggleMaintenance: (isActive, message) => dispatch(toggleMaintenance(isActive, message)),
    setDynamicStrings: (dynamicStrings) => dispatch(setDynamicStrings(dynamicStrings)),
  };
};

const withStore = connect(storePropsMapper, storeActionsMapper);

export default withStore;
