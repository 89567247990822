import React from "react";
import Grid from "../components/Grid/Grid";
import PersonInfo from "../components/PersonInfo/PersonInfo";
import { featutesInTileMapper } from "../utils";

enum Component {
  PersonInfo,
  Grid,
};

const getComponentProps = (components) => {
  return components.flatMap((component, index) => {
    switch (component.type.toLowerCase()) {
      case "biography":
        return [{
          type: Component.PersonInfo,
          componentType: component.type,
          info:  {
            name : component.name,
            biography: component.biography,
            imageUrl: component.image && component.image.url,
            roles: component.roles
          }
        }, {
          type: Component.Grid,
          header: 'Features in',
          tiles: (component.roles && component.roles.length > 0) ?
            featutesInTileMapper(component.roles) : []
        }]

      default:
        return null;
    }
  });
}

const getComponents = (components) => {
  const componentProps = getComponentProps(components) || [];
  return componentProps.map((fullProps, index) => {
    const { type, ...props } = fullProps;
    let component;
    switch (type) {
      case Component.PersonInfo:
        component =  <PersonInfo key={index} {...props} />;
        break;
      case Component.Grid:
        component =  <Grid key={index} {...props} />;
        break;
    }
    return component;
  })
}

const getPageInfo = (person) => {
  return {
    title: person.name || "Met Opera On Demand",
    id: person.id
  }
};

export default function parse(person) {
  return {
    ...getPageInfo(person),
    components: person.components && getComponents(person.components)
  }
};

export { Component };
