export interface StoreState {
  language: string;
  user: any;
  userFetchPending: boolean;
  displaySignIn: boolean;
  inMaintenance: boolean;
  maintenanceMessage?: string;
  dynamicStrings: any;
};

export interface GenericAction {
  type: string,
  data?: any
};

export enum StorageField {
  LoggedIn = "LoggedIn"
};

export function storageKey(field) {
  return `MetOpera.${field}`
}
