import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { SVGImage } from "../../assets/images";
import Slider from "react-slick";
import Slide from "../Slide/Slide";
import "./Hero.scss";
import {HERO_SLIDE_VH, DESKTOP_MARGIN, BREAKPOINT_TABLET, BREAKPOINT_MOBILE} from "../../utils/constants";
import SubscribersOptions from "../SubscribersOptions/SubscribersOptions";

class Hero extends React.Component<any,any> {
  slider;
  slideHeight;
  appHeaderObserver;
  appHeaderHeight;

  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      infoIndex: 0,
      slidesCount: props.slides.length,
    };
  }

  componentDidMount() {
    document.querySelector(".Header").classList.add("hero-active");
    window.addEventListener("resize", this.onResize);
    this.onResize();
    window.scrollTo({top: 0});

    const appHeader = document.querySelector("#app-header");
    this.appHeaderHeight = appHeader.offsetHeight;
    this.appHeaderObserver = new ResizeObserver(function() {
      if (appHeader.offsetHeight !== this.appHeaderHeight) {
        window.scrollTo({top: window.scrollY});
        this.appHeaderHeight = appHeader.offsetHeight;
      }
    }.bind(this));
    this.appHeaderObserver.observe(appHeader);
  }

  componentWillUnmount() {
    document.querySelector(".Header").classList.remove("hero-active");
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("scroll", this.onTabletScroll);
    window.removeEventListener("resize", this.onResize);
    this.appHeaderObserver.unobserve(document.querySelector("#app-header"));
  }

  setActiveNavigationDot = (index) => {
    document.querySelectorAll(".Hero-navigation-dots li").forEach((el) => {
      if (Number(el.getAttribute("data-index")) !== index) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
    });
  }

  onResize = () => {
    const homePageContent = document.querySelector(".HomePage > .content"),
      hero = document.querySelector("div.Hero"),
      subscribersOptions = document.querySelector(".Hero-subscriptions-container"),
      dots = hero.querySelector(".Hero-navigation-dots"),
      vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    this.slideHeight = vh * (HERO_SLIDE_VH / 100) - DESKTOP_MARGIN * 2;
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("scroll", this.onTabletScroll);
    subscribersOptions.style.removeProperty("opacity");

    if (window.innerWidth > BREAKPOINT_TABLET) {
      homePageContent.style.marginTop = `calc(${HERO_SLIDE_VH * (this.state.slidesCount - 1)}vh - ${DESKTOP_MARGIN * this.state.slidesCount}px)`;
      dots.style.height = `${12 * this.state.slidesCount + 15 * (this.state.slidesCount - 1)}px`;
      window.addEventListener("scroll", this.onScroll);
      setTimeout(function() {
        if (window.innerWidth > BREAKPOINT_TABLET) {
          this.onScroll();
          const scrollY = window.scrollY;
          if (scrollY > 0) {
            window.scrollTo({top: scrollY - 1});
          }
        }
      }.bind(this), 50);
    }
    else {
      homePageContent.style.removeProperty("margin-top");
      hero.style.removeProperty("opacity");
      hero.classList.remove("scrolled");
      hero.classList.remove("fully-scrolled");
      document.querySelectorAll(".Hero-slider .Slide").forEach(function(el) {
        el.style.removeProperty("margin-top");
      });
      dots.style.removeProperty("height");
      subscribersOptions.classList.remove("active");

      if (window.innerWidth > BREAKPOINT_MOBILE) {
        window.addEventListener("scroll", this.onTabletScroll);
        setTimeout(function() {
          if (window.innerWidth > BREAKPOINT_MOBILE && window.innerWidth <= BREAKPOINT_TABLET) {
            this.onTabletScroll();
          }
        }.bind(this), 50);
      }
    }
  }

  onScroll = () => {
    const scrollY = window.scrollY,
      headerOffset = document.querySelector("#app-header").offsetHeight,
      heroHeader = document.querySelector(".Hero-header"),
      subscribersOptions = document.querySelector(".Hero-subscriptions-container"),
      heroDots = document.querySelector(".Hero-navigation-dots");

    if (scrollY >= headerOffset) {
      const unparsedIndex = (scrollY + 1 - headerOffset) / this.slideHeight,
      decimals = unparsedIndex - Math.floor(unparsedIndex),
      newIndex = Math.trunc(unparsedIndex) > this.state.slidesCount - 1 ? this.state.slidesCount - 1 : Math.trunc(unparsedIndex),
      infoIndex = newIndex === this.state.slidesCount - 1 ? newIndex : decimals > 0.5 ? Math.ceil(unparsedIndex) : Math.floor(unparsedIndex),
      minScrollHeight = (this.state.slidesCount - 1) * this.slideHeight + 1 + headerOffset,
      hero = document.querySelector("div.Hero"),
      header = document.querySelector(".Header");

      document.querySelectorAll(".Hero-slider .Slide").forEach(function(el, i) {
        if (i > this.state.slideIndex) {
          el.classList.remove("active");
          el.classList.add("inactive");
          el.style.marginTop = i > 0 ? `-${headerOffset}px` : "0";
        }
        else {
          el.classList.add("active");
          el.classList.remove("inactive");
          el.style.marginTop = i > 0 ? `calc(${HERO_SLIDE_VH * i}vh - ${DESKTOP_MARGIN * 2 * i}px)` : "0";
        }
      }.bind(this));
      heroHeader.classList.add("active");
      subscribersOptions.classList.add("active");
      heroDots.classList.add("active");
      document.querySelectorAll(".Hero-slider .Slide .slide-overlay-info-wrapper").forEach(function(el) {
        el.classList.add("active");
      });

      if (this.state.slideIndex !== newIndex) {
        this.setActiveNavigationDot(newIndex);
        this.setState({
          slideIndex: newIndex,
        });
      }

      if (this.state.infoIndex !== infoIndex) {
        document.querySelectorAll(".Hero-slider .Slide").forEach(function(el, i) {
          if (i !== infoIndex) {
            el.classList.remove("info-active");
          }
          else {
            el.classList.add("info-active");
          }
        });
        this.setState({
          infoIndex: infoIndex,
        });
      }

      let delta = (minScrollHeight + this.slideHeight - scrollY) / this.slideHeight;
      delta = delta > 1 ? 1 : delta < 0 ? 0 : delta;
      hero.style.opacity = Math.abs(delta);

      if (scrollY > minScrollHeight) {
        hero.classList.add("scrolled");
      }
      else {
        hero.classList.remove("scrolled");
      }

      if (scrollY > minScrollHeight + 200) {
        hero.classList.add("fully-scrolled");
        header.classList.remove("hero-active");
      }
      else {
        hero.classList.remove("fully-scrolled");
        header.classList.add("hero-active");
      }
    }
    else {
      document.querySelectorAll(".Hero-slider .Slide").forEach(function(el) {
        el.classList.remove("active");
        el.classList.remove("inactive");
      });
      heroHeader.classList.remove("active");
      subscribersOptions.classList.remove("active");
      heroDots.classList.remove("active");
      document.querySelectorAll(".Hero-slider .Slide .slide-overlay-info-wrapper").forEach(function(el) {
        el.classList.remove("active");
      });
    }
  }

  onTabletScroll = () => {
    const scrollY = window.scrollY,
      headerOffset = document.querySelector("#app-header").offsetHeight,
      subscribersOptions = document.querySelector(".Hero-subscriptions-container");

    if (scrollY >= headerOffset) {
      const hero = document.querySelector("div.Hero"),
        heroHeightScrolled = hero.offsetHeight * 0.50,
        minScrollHeight = heroHeightScrolled + headerOffset;

      let delta = (minScrollHeight - scrollY) / heroHeightScrolled;
      delta = delta > 1 ? 1 : delta < 0 ? 0 : delta;
      subscribersOptions.style.opacity = Math.abs(delta);

      subscribersOptions.classList.add("active");
    }
    else {
      subscribersOptions.classList.remove("active");
    }
  }

  onClick = (index) => {
    const headerOffset = document.querySelector("#app-header").offsetHeight,
      scrollPosition = index * this.slideHeight + headerOffset;
    window.scrollTo({top: scrollPosition, behavior: "smooth"});
    this.onScroll();
  }

  render() {
    const { slides, store: { user, userFetchPending } } = this.props;
    const settings = {
      dots: true,
      autoplay: false,
      infinite: true,
      arrows: false,
      mobileFirst: true,
      responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: BREAKPOINT_TABLET,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    }
    return (
      <div className="Hero">
        <div className="Hero-header">
          {!userFetchPending && !user &&
            <img src={SVGImage.MoodLogo} alt="MetOpera On Demand Logo" className="mood-logo" />
          }
        </div>
        <div className="Hero-subscriptions-container">
          {!userFetchPending && user &&
            <SubscribersOptions />
          }
        </div>
        <Slider {...settings} className="Hero-slider">
          {
            slides.map((slide, index) => {
              return <Slide key={index} index={index} zIndex={(this.state.slidesCount - index) * 10} {...slide} />
            })
          }
        </Slider>
        <ul className={`Hero-navigation-dots ${user ? "user-logged-in" : ""}`}>
          {
            slides.map((slide, index) => {
              return <li key={index} data-index={index} className={index === 0 ? "active" : ""} onClick={() => this.onClick(index)}></li>
            })
          }
        </ul>
      </div>
    )
  }
};

export default compose(withRouter, withStore)(Hero);
