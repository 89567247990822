import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { Button } from "@brightcove/studio-components";
import ExternalLink from "../ExternalLink/ExternalLink";
import { SVGImage } from "../../assets/images";
import "./SlideInfo.scss"

class SlideInfo extends React.Component<any, any> {

  showLogIn = () => {
    this.props.showLogIn();
  }

  render() {
    const { header, subHeader, url, ctaLabel, mediaType, fixed, store: { user } } = this.props;
    return (
      <div className={`slide-overlay-info-wrapper ${fixed ? 'fixed' : ''}`}>
        <div className="slide-overlay-info">
          {!user &&
            <div className="login-ctas">
              <Button theme="classic" className="btn btn-pink" onClick={this.showLogIn}>Start free 7-day trial</Button>
              <Button theme="classic" className="btn btn-transparent white" onClick={this.showLogIn}>Login</Button>
              <ExternalLink url="https://www.metopera.org/season/on-demand/gift-subscriptions/" text="Give/Redeem Gift Subscription" />
            </div>
          }
          <div className="metadata">
            {subHeader && <h3 className="subheader">{subHeader}</h3>}
            <h2 className="header"><i><span>{header}</span></i></h2>
            <NavLink to={url} className="watch-now" exact><span className="play-icon-wrapper"><img src={SVGImage.PlayIcon} alt="Play Icon" className="play-icon" /></span>{ctaLabel || (mediaType === "audio" ? "Listen Now" : "Watch Now")}</NavLink>
          </div>
        </div>
      </div>
    )
  }
};

export default compose(withRouter, withStore)(SlideInfo);

