import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { withStore } from "../../store";
import BroadcastParser from "../../parser/BroadcastParser";
import Fetch from "../Fetch/Fetch";
import Loading from "../Loading/Loading";
import { getActiveItem, fetchHandler, getClientApiURL } from "../../utils";
import "./AudioBroadcast.scss";

class AudioBroadcast extends React.Component<any, any> {

  scheduleInterval;

  constructor(props) {
    super(props);
    this.state = {
      activeItem: undefined,
      components: this.props.components,
      player: null
    };
  }

  componentDidMount() {
    const items = this.getBroadcastSchedule();
    const activeItem = getActiveItem(items);
    if (activeItem) {
      this.setState({activeItem});
    }
    this.setActiveItem();
  }

  setActiveItem = () => {
    const items = this.getBroadcastSchedule();
    this.scheduleInterval = setInterval(() => {
      const activeItem = getActiveItem(items);

      if (
        this.state.activeItem
        && activeItem
        && this.state.activeItem.start !== activeItem.start
        // && (!this.state.player || this.state.player.isDisposed_ || this.state.player.ended())
      ) {
        this.setState({activeItem});
        this.getBroadCastInfo();
      }
    }, 1000);
  }

  getBroadcastSchedule = () => {
    const { components } = this.props;
    const schedule = components.filter(component => (component.type.toLowerCase() === "audio_stream_schedule"));
    return schedule.length > 0 && schedule[0].items;
  }

  getBroadCastInfo = () => {
    const broadcastUrl = getClientApiURL(`/performance/${this.state.activeItem.id}`);
    fetchHandler(broadcastUrl, {}, (data) => {
      if(data.components) {
        this.setState({
          components: data.components
        });
      }
    });
  }

  onPlayerCreated = (player) => {
    this.setState({
      player
    });
  }

  componentWillUnmount() {
    this.scheduleInterval && clearInterval(this.scheduleInterval);
  }

  render() {
    const { activeItem, components } = this.state;
    // const url = activeItem && `${getClientApiURL()}/performance/${activeItem.parentPerformance.id}`;
    let screen = BroadcastParser({}, components, activeItem, this.onPlayerCreated);
    const items = this.getBroadcastSchedule();

    if (items && items.length && activeItem) {
      screen = BroadcastParser(activeItem, components, activeItem, this.onPlayerCreated);
      const isLastItem = items[items.length - 1].id === activeItem.id;

      return (
        <div className={`Broadcast-content${isLastItem ? " broadcast-ending" : ""}`}>
          { screen.components }
        </div>
      )
    } else {
      return <Loading />
    }

  }
};

export default compose(withRouter, withStore)(AudioBroadcast);
