import React from "react";
import classnames from "classnames";
import "./Loading.scss";

const Loading: React.FunctionComponent<any> = (props) => {
  const loadingClassName = classnames("Loading-indicator", {
    "Loading-overlay": !props.inline,
    "Loading-label": props.label,
    "Loading-centered": props.centered,
  }, props.className)
  return <div className={loadingClassName}><p className="spinner"></p>{props.label}</div>
};

export default Loading;
