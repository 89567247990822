import React from "react";
import { compose } from "react-apollo";
import { withStore } from "../../store";
import { SVGImage, SVG } from "../../assets/images";
import classnames from "classnames";
import { getPageType } from "../../utils";
import "./PerformanceActions.scss";

class PerformanceActions extends React.Component<any,any> {

  render() {
    const { inFavorites, onToggleFavorite, store: { user } } = this.props;
    const url = window.location.href;
    const pageType = getPageType();
    const isBroadcastPage = (pageType === "broadcast");
    const isStudent = user && user.data && user.institutionName

    return (
      <div className="performance-actions">
        { !isBroadcastPage && user && !isStudent &&
          <div className={classnames("performance-favorite", inFavorites ? "in-favorites" : "")} onClick={onToggleFavorite}>
            <span><SVG.FavoriteIcon /></span><span>Favorite</span>
          </div>
        }
        <div className="performance-social-share">
          <ul className="performance-social-share-buttons">
            <li><a href={`http://www.facebook.com/sharer.php?u=${url}`} target="_blank" rel="noreferrer" className="fb"><SVG.Facebook title="Share on Facebook" aria-labelledby="title" /></a></li>
            <li><a href={`http://twitter.com/home?status=${url}`} target="_blank" rel="noreferrer" className="twitter"><SVG.Twitter title="Share on Twitter" aria-labelledby="title" /></a></li>
          </ul>
          <div className="performance-share-trigger">
            <p><img src={SVGImage.Share} alt="Share Icon" />Share</p>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(withStore)(PerformanceActions);
