import React from "react";
import "./Tile.scss";
import AppLink from "../AppLink/AppLink";
import { Button } from "@brightcove/studio-components";
import { SVGImage } from "../../assets/images";
import classnames from "classnames";
import { formatDate, getPerformanceYearRange, getCastByType } from "../../utils";

class Tile extends React.Component<any, any> {

  getCastAndCrew = (cast) => {
    const composers = cast.filter(cast => {
      return (cast.role !== "COMPOSER" || cast.role !== "LIBRETTIST" || cast.role !== "CONDUCTOR" || cast.role !== "GROUP")
    }).map(cast => cast.person.name);
    return composers.length ? composers.join(', ') : '';
  }

  render() {
    const {  mediaType, header, runTime, resolutionType, previewableChapters, hasAudioDescription, hasCaptions, imageurl, badge, performanceDateISO, cast, url, position, ctaLabel, tier, shortCast } = this.props;
    const performanceYearRange = getPerformanceYearRange(performanceDateISO);
    const composer = (cast && getCastByType(cast, "composer")) || '';
    const conductor = (cast && getCastByType(cast, "conductor")) || '';
    const isDetailPage = window.location.pathname.indexOf("/performance/detail") > -1;

    return (
      <AppLink to={url}>
        <div className="Tile">
          <div className={classnames("Tile-image-container", (mediaType === "audio" || mediaType === "audio_stream") ? `audio-${performanceYearRange}s` : '')}>
            { mediaType !== "audio" && mediaType !== "audio_stream" && imageurl && <img src={imageurl} alt="Performance" /> }
            { (!previewableChapters && (tier && tier.toLowerCase() !== "free")) &&
              <span className="Tile-item-mediatype-icon">
                { (mediaType === "audio" || mediaType === "audio_stream") ? <img src={SVGImage.Audio} alt="Audio Icon" /> : mediaType === "video" || mediaType === "short_form" ? <img src={SVGImage.PlayCircle} alt="Play Icon" /> : ''}
              </span>
            }
            { ((previewableChapters || (tier && tier.toLowerCase() === "free"))) &&
              <span className="Tile-item-free-preview">
                <img src={SVGImage.FreePreview} alt="Free Preview" />
              </span>
            }
            { (mediaType === "audio" || mediaType === "audio_stream") &&
              <div className="Tile-image-container-overlay">
                <p>Live Audio Recording</p>
                <p>{performanceYearRange}s</p>
              </div>
            }
            { resolutionType && resolutionType === "HD" && <p className="Tile-marker left hd"><span>{resolutionType}</span></p> }
            { (hasCaptions || hasAudioDescription) &&
              <p className="Tile-marker right">
                { hasCaptions && <span className="Tile-marker-cc">CC</span> }
                { hasAudioDescription && <span className="Tile-marker-ad">AD</span> }
              </p>
            }
            { (position && runTime) &&
              <div className="Tile-progress">
                <div className="Tile-progress-bar" style={{ width: `${position/runTime*100}%` }}/>
              </div>
            }
          </div>
          <div className="Tile-body">
            <div className={classnames("Tile-body-metadata", composer && performanceDateISO ? 'has-medatadata' : '')}>
              { composer && <span className="Tile-body-pre-title">{composer}</span> }
              { performanceDateISO && <span className="Tile-body-performance-date">{formatDate(performanceDateISO)}</span> }
              { badge && <span className="Tile-body-badge">{badge}!</span> }
            </div>
            <h2 className="Tile-body-header">{header}</h2>
              { shortCast && <div className="Tile-body-cast">
              {/* { conductor && <span>{conductor}; </span>} */}
              <div dangerouslySetInnerHTML={{__html: shortCast}}></div>
            </div> }
            <Button theme="classic" text={ctaLabel || ((mediaType === "audio" || mediaType === "audio_stream") ? "Listen Now" : "Watch Now")} className={classnames("Tile-cta btn", isDetailPage ? 'btn-transparent white' : (mediaType === "audio" ? 'btn-transparent black' : 'btn-pink'))} onClick={() => {return false}} />
          </div>
        </div>
      </AppLink>
    )
  }
}

export default Tile;
