import React from "react";
import { Button } from "@brightcove/studio-components";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { fetchHandler, getClientApiURL } from "../../utils";
import "./BeforeYouProceed.scss";



const BeforeYouProceed: React.FunctionComponent<any> = ({ store: { language }, history }) => {

  const handleLogout = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const redirectUrl = queryParams.get('redirectUrl');

    const logoutUrl = `${getClientApiURL("/logout")}`;
    fetchHandler(logoutUrl, {}, () => {
      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        history.replace("/")
      }
    }, undefined);
  }

  return (
    <div className="BeforeYouProceed">
        <h2>Before You Proceed to Met Website</h2>
        <div className="rich-text">
          <p><strong>Please Note:</strong></p>
          <p>You have selected a link or web page outside of Met Opera on Demand: <i>Student Access</i>. If you proceed, you will be redirected and may need to log in again if your <i>Student Access</i> browser window closes. It may be necessary to clear your browser cache and cookies in order to reinstate the auto-login to <i>Student Access</i>.</p>
<p>
We invite you to visit other parts of the Metropolitan Opera website, but you will be logged out and redirected outside of your school/proxy connection to Met Opera on Demand: <i>Student Access</i>.
</p>
<div>
          <Button theme="classic" Component="a" componentProps={{href: "/"}} className="btn btn-red">Return to Met Opera On Demand</Button>
          <Button theme="classic" onClick={handleLogout} className="btn btn-transparent red margin-left-1">Continue to full site</Button>
          </div>
        </div>
    </div>
  )
};

export default compose(withStore)(BeforeYouProceed);
