import React from "react";
import "./MaintenanceOverlay.scss";

const MaintenanceOverlay: React.FunctionComponent<any> = ({ message }) => {
    return (
      <div className="MaintenanceOverlay">
        <div className="header">Sorry, MetOpera On Demand Site is under maintenance</div>
        <div className="message" dangerouslySetInnerHTML={{__html: message}} />
      </div>
    )
}

export default MaintenanceOverlay;
