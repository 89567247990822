import React from "react";
import { withRouter } from "react-router-dom";
import { Input } from "@brightcove/studio-components";
import { SVG } from "../../assets/images";
import classnames from "classnames";
import _ from "lodash";
import queryString from 'query-string';
import "./SearchInput.scss";

class SearchInput extends React.Component<any, any> {
  static defaultProps = {
    minCharacters: 3,
  }

  constructor(props) {
    super(props);
    const parsed = queryString.parse(props.location.search);
    this.state = {
      value: parsed.q || "",
      text: this.defaultText(),
      error: false,
      debouncedOnChange: _.debounce(props.onChange, 750),
    }
  }

  defaultText = () => {
    return `Enter a minimum of ${this.props.minCharacters} characters for results.`
  }

  errorText = () => {
    return this.props.errorText || `Sorry, there are no results for '${this.state.value}'.`;
  }

  componentDidUpdate(prevProps) {
    const { value } = this.state;
    const { minCharacters } = this.props;
    if(!prevProps.error && this.props.error) {
      this.setState({ text: this.errorText() });
    }
    else if(prevProps.error && !this.props.error) {
      this.setState({ text: value.length >= minCharacters ? undefined : this.defaultText() });
    }
  }

  onChange = (value) => {
    const { minCharacters, onChange } = this.props;
    const { debouncedOnChange } = this.state;
    this.setState({ value, text: value.length >= minCharacters ? undefined : this.defaultText() });

    if(onChange && debouncedOnChange) {
      if ((value.length >= minCharacters || !value.length))
        debouncedOnChange(value);
      else {
        debouncedOnChange.cancel();
        onChange("");
      }
    }
  };

  onKeyDown = (event) => {
    const { history } = this.props;
    if (event.keyCode === 13 && !this.state.value.length) {
      history.push("/search");
    }
  };

  clearSearch = () => {
    this.setState({ value : "", text: this.defaultText() });
    this.props.onClearSearch();
  }

  render() {
    const { value, text } = this.state;
    const { error, placeholder, autoFocus, showCloseIconOnFocus } = this.props;
    return (
      <div className="search-input">
        <div className="search-bar">
          <SVG.Search className="search-bar-icon"/>
          <Input
           className="search-bar-field"
           placeholder={placeholder || "Search"}
           onFocus={this.props.onFocus}
           onChange={this.onChange}
           onKeyDown={this.onKeyDown}
           autoFocus={autoFocus}
           value={value}/>
          { (value || showCloseIconOnFocus) && <SVG.CloseThin className="search-bar-close-icon" onClick={this.clearSearch}/> }
        </div>
        { text && <div className={classnames(["search-text", { error: error }])}>{text}</div> }
      </div>
    )
  }
}

export default withRouter(SearchInput);
