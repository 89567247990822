import React from "react";
import "./Tile.scss";
import AppLink from "../AppLink/AppLink";
import { Button } from "@brightcove/studio-components";
import { getAllRoles } from "../../utils";

class PersonTile extends React.Component<any, any> {

  render() {
    const { name, roles, url, imageurl } = this.props;

    return (
      <AppLink to={url}>
        <div className="Tile person">
          <div className="Tile-image-container">
            { imageurl && <img src={imageurl} alt="Person" /> }
          </div>
          <div className="Tile-body">
            <div className="Tile-body-metadata">
              { roles && <span className="Tile-body-pre-title">{getAllRoles(roles)}</span> }
            </div>
            <h2 className="Tile-body-header">{name}</h2>
            <Button theme="classic" text="View" className="Tile-cta btn btn-transparent black" onClick={() => {return false}} />
          </div>
        </div>
      </AppLink>
    )
  }
}

export default PersonTile;
