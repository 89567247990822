import Facebook from "./icons/facebook.svg";
import Twitter from "./icons/twitter.svg";
import TikTok from "./icons/tiktok.svg";
import Instagram from "./icons/instagram.svg";
import Youtube from "./icons/youtube.svg";
import Menu from "./icons/menu.svg";
import Login from "./icons/login.svg";
import Calendar from "./icons/calendar.svg";
import Search from "./icons/search.svg";
import Close from "./icons/close.svg";
import CloseThinIcon from "./icons/close-thin.svg";
import PlayIcon from "./icons/play.svg";
import MoodLogo from "./icons/mood-logo.svg";
import MetOperaLogo from "./icons/metopera-logo.svg";
import PlayCircle from "./icons/icon-play-circle.svg";
import Audio from "./icons/icon-grid-radio.svg";
import BackArrow from "./icons/icon-back.svg";
import ArrowDown from "./icons/icon-arrow-down.svg";
import FreePreview from "./icons/free-preview.svg";
import Share from "./icons/share-icon.svg";
import PlayBtn from "./icons/btn-play-rounded.svg";
import PauseBtn from "./icons/btn-pause-rounded.svg";
import SlickPrev from "./icons/btn-arrow-prev.svg";
import SlickNext from "./icons/btn-arrow-next.svg";
import LeftPointer from "./icons/icon-left-pointer.svg";
import Alert from "./icons/icon-alert.svg";
import Chat from "./icons/chat.svg";

import TollBrothers from './footer/tollBrothers.png';
import Sirius from './footer/sirius.png';

import devicePoster from './app-downloads/device-poster.png';
import appStoreLogo from './app-downloads/app-store.jpeg';
import googlePlayStoreLogo from './app-downloads/google-play-store.jpeg';
import amazonAppStoreLogo from './app-downloads/amazon-app-store.jpeg';
import roku from './app-downloads/roku.jpeg';
import samsung from './app-downloads/samsung.jpeg';

import { ReactComponent as SearchIconComponent } from "./icons/search.svg";
import { ReactComponent as ChatIconComponent } from "./icons/chat.svg";
import { ReactComponent as CloseIconComponent } from "./icons/close.svg";
import { ReactComponent as CloseThinIconComponent } from "./icons/close-thin.svg";
import { ReactComponent as FacebookIconComponent } from "./icons/facebook.svg";
import { ReactComponent as TwitterIconComponent } from "./icons/twitter.svg";
import { ReactComponent as CalendarComponent } from "./icons/calendar.svg";
import { ReactComponent as MetLogoComponent } from "./icons/met-logo-color-stacked.svg";
import { ReactComponent as BackArrowComponent } from "./icons/icon-back.svg";
import { ReactComponent as ForwardArrowComponent } from "./icons/arrow-forward.svg";
import { ReactComponent as FavoriteIconComponent } from "./icons/favorite-icon.svg";
import { ReactComponent as LeftPointerComponent } from "./icons/icon-left-pointer.svg";
import { ReactComponent as AlertComponent } from "./icons/icon-alert.svg";
import { ReactComponent as ArrowDownComponent } from "./icons/icon-arrow-down.svg";


const SVGImage = {
  Facebook,
  TikTok,
  Twitter,
  Instagram,
  Youtube,
  Menu,
  Login,
  Calendar,
  Search,
  Close,
  CloseThinIcon,
  PlayIcon,
  MoodLogo,
  MetOperaLogo,
  PlayCircle,
  Audio,
  BackArrow,
  ArrowDown,
  FreePreview,
  Share,
  PlayBtn,
  PauseBtn,
  SlickPrev,
  SlickNext,
  LeftPointer,
  Alert,
  Chat
};

const Image = {
  TollBrothers,
  Sirius,
  devicePoster,
  appStoreLogo,
  googlePlayStoreLogo,
  amazonAppStoreLogo,
  roku,
  samsung
}

const SVG = {
  Search: SearchIconComponent,
  Close: CloseIconComponent,
  CloseThin: CloseThinIconComponent,
  Facebook: FacebookIconComponent,
  Twitter: TwitterIconComponent,
  Calendar: CalendarComponent,
  MetLogo: MetLogoComponent,
  BackArrow: BackArrowComponent,
  ForwardArrow: ForwardArrowComponent,
  FavoriteIcon: FavoriteIconComponent,
  LeftPointer: LeftPointerComponent,
  Alert: AlertComponent,
  ArrowDown: ArrowDownComponent,
  Chat: ChatIconComponent
};

export { SVG, SVGImage, Image };
