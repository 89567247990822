import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import Loading from "../../components/Loading/Loading";
import Broadcast from "../../components/Broadcast/Broadcast";
import { getClientApiURL } from "../../utils"
import "./BroadcastPage.scss";

class BroadcastPage extends React.Component<any, any> {
  render() {
    const url = getClientApiURL(window.location.pathname);
    return (
      <Fetch
        url={url}
        addMiddlewareHeaders
        options={{ credentials: "include" }}
        authzBeforeRequest
        >
        {(loading, errors, data) => {
          const slide = data && data.slide
          return (
            errors ?
              <ErrorOverlay /> :
              <div className="BroadcastPage">
                { loading && <Loading /> }
                { data &&
                  <React.Fragment>
                    <div className="Broadcast-hero">
                      <div className="Broadcast-hero-banner">
                        { slide.image && slide.image.url &&
                            <img src={slide.image.url} alt="Banner" />
                        }
                      </div>
                      <div className="Broadcast-hero-title">
                        <h3>{data.name}</h3>
                      </div>
                    </div>
                    <Broadcast {...data} />
                  </React.Fragment>
                }
              </div>
          )
        }}
      </Fetch>
    )
  }
};

export default compose(withRouter, withStore)(BroadcastPage);
