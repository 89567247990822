import React from "react";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../../store";
import brightcovePlayerLoader from '@brightcove/player-loader';
import { Button } from "@brightcove/studio-components";
import { SVGImage } from "../../../assets/images";
import PerformanceInfo from "../../../components/PerformanceInfo/PerformanceInfo";
import { formatDate, getComposerLastName, getPerformanceYearRange } from "../../../utils";
import classnames from "classnames";
import "../Player.scss";
import "./Player.scss";

function getCurrentMachineTime(delta?) {
  if (typeof delta !== 'number') {
    delta = 0;
  }
  return new Date().getTime() - delta;
}


class Player extends React.Component<any, any> {
  player;
  liveCheckInterval;


  constructor(props) {
    super(props);
    this.state = {
      hideMoreInfo: true,
      paused: true,
      isLive: true,
      hasStarted: false,
      activeTrack: null
    }
  }

  componentDidMount() {
    if (this.player && this.player.isDisposed_) {
      this.player.dispose();
    }
    this.brightcovePlayer();
    this.liveCheckInterval = setInterval(() => {
      if (this.isSessionLive()) {
        if (!this.state.hasStarted) this.setState({ hasStarted: true });
      } else {
        if (this.state.hasStarted) {
          this.player.dispose();
          clearInterval(this.liveCheckInterval);
          this.setState({
            isLive: false,
            hasStarted: false,
          });
        }
      }
    }, 1000);
  }

  isSessionLive = () => {
    const { start, end } = this.props.activeItem;
    const currentTime = new Date().getTime(),
        startTime = new Date(start).getTime(),
        endTime = new Date(end).getTime();

    if (currentTime >= startTime && currentTime <= endTime) {
      return true;
    }
    return false;
  }

  toggleInfo = () => {
    this.setState((prev) => {
      return {hideMoreInfo: !prev.hideMoreInfo};
    });
  }

  brightcovePlayer = () => {
    return loadBrightcovePlayer.call(this, this.props);
  }


  togglePlay = () => {
    if (this.player) {
      const { store: { user }, tier } = this.props;

      if(!user && (tier && tier.toLowerCase() !== 'free')) {
        return;
      }
      const isPaused = this.player.paused();
      const playPauseToggle = this.player.el_.querySelector("#bcc-play-pause-toggle-button");

      if (playPauseToggle) {
        playPauseToggle.click();
        this.setState({
          paused: !isPaused
        })
      }
    }
  }

  componentWillUnmount() {
    if (!this.player.isDisposed_) {
      this.player.dispose();
    }
    this.liveCheckInterval && clearInterval(this.liveCheckInterval);
  }

  render() {
    const { hideMoreInfo, paused, isLive, hasStarted, activeTrack } = this.state;
    const { showLogIn, store: { user, dynamicStrings }, info: { cast, performanceDateISO, header, mediaType, imageurl }, tier } = this.props;
    const showLoginOverlay = (!user && tier.toLowerCase() !== "free");
    const composerLastName = getComposerLastName(cast);
    const performanceYearRange = getPerformanceYearRange(performanceDateISO);

    console.log("mediaType: ", mediaType)
    return (
      <div className={`Player-wrapper ${mediaType}${isLive ? "" : " hasEnded"}`}>
        {(mediaType === "audio" || mediaType === "audio_stream" ) &&
          <div className="performance-info-wrapper">
            <div className={classnames("performance-image", `audio-${performanceYearRange}s`)}>
              <div className="performance-image-overlay">
                <p>Live Audio Recording</p>
                <p>{performanceYearRange}s</p>
              </div>
            </div>
            <div className={`performance-info ${showLoginOverlay ? "show-login-overlay" : ""} `}>
              <p className="performance-composer-date">
                <span>{composerLastName}</span>
                <span className="separator">|</span>
                <span>{formatDate(performanceDateISO, true)}</span>
              </p>
              { header && <h2 className="performance-title">{header}</h2> }
              { activeTrack &&
                <div className="performance-active-track">
                  <div className="performance-active-track-title">{activeTrack.title}</div>
                  <div className="performance-active-track-cast">{activeTrack.artists}</div>
                </div>
              }
              <div className="Player-controls">
                { isLive && hasStarted &&
                  <div className="play-pause-btn" onClick={this.togglePlay}>
                    <img src={paused ? SVGImage.PlayBtn : SVGImage.PauseBtn} alt={paused ? "Play Button" : "Pause Button"} />
                  </div>
                }
                {showLoginOverlay &&
                  <div className="subscribe-overlay">
                    <Button theme="classic" className="btn btn-pink" onClick={showLogIn}>Login / Subscribe to watch</Button>
                  </div>
                }{
                  // (mediaType !== "audio" && mediaType !== "audio_stream") &&
                  <div className="performance-info-toggle-cta">
                    <Button theme="classic" className="btn btn-transparent white" onClick={this.toggleInfo}>{hideMoreInfo ? <span>More Info</span> : <span> - Hide Info</span>}</Button>
                  </div>
                }
              </div>
            </div>
          </div>
        }
        <div className="Player">
          {showLoginOverlay &&
            <div className="subscribe-overlay">
              <Button theme="classic" className="btn btn-pink" onClick={showLogIn}>Login / Subscribe to watch</Button>
            </div>
          }
        </div>
        <div className="broadcast-ended-message">{dynamicStrings["broadcast_ended_message"]}</div>
        {!hideMoreInfo &&
          <PerformanceInfo {...this.props} />
        }
      </div>
    )
  }
};

function loadBrightcovePlayer(this: Player, props) {
  const { start, end } = this.props.activeItem || {};
  const { store: { user }, info: { extId, mediaType }, onPlayerCreated, tier } = this.props;
  const accountId = mediaType === "short_form" ? process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID_SHORT_FORM : process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID_LONG_FORM;
  const playerId = mediaType === "short_form" ? process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_SHORT_FORM : process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_LONG_FORM_BROADCAST;

  const playerSettings = {
    refNode: ".Player",
    refNodeInsert: 'append',
    accountId: accountId,
    playerId: playerId,
    embedId: 'default',
    videoId: `ref:${extId}`,
    options: {
      id: 'player',
      playsinline: true
    },
    onEmbedCreated: function(embed){
      embed.removeAttribute("controls");
    }
  }

  return brightcovePlayerLoader(playerSettings).then((success) => {
    this.player = success.ref;
    onPlayerCreated(this.player);
    let userTier = tier && tier.toLowerCase();

    this.player.MetBroadcastPlayer({
      user,
      startTime: start,
      endTime: end
    });

    this.player.on("play", () => {
      if (!user && userTier !== "free") {
        this.player.pause();
      } else {
        this.setState({
          paused: false
        });
      }
    });

    const getTrackInfo = (cue) => {
      const metadata = cue && cue.originalCuePoint && cue.originalCuePoint.metadata;
      if (metadata) {
        const [title, artists] = metadata.split('|');
        return {
          title: title.replace('title=', ''),
          artists: artists.replace('artists=', '')
        }
      }
      return {};
    }

    this.player.on("loadstart", () => {
      if (!user && userTier !== "free") {
        this.player.addClass('vjs-login-overlay-showing');
      }

      const textTracks = this.player.textTracks();
      if (textTracks.length) {
        textTracks[0].oncuechange = () => {
          const activeCue = textTracks[0].activeCues[0];
          const activeTrack = getTrackInfo(activeCue);
          this.setState({
            activeTrack
          });
        }
      }
    });

    this.player.on("timeupdate", () => {
      const currentTime = getCurrentMachineTime();
      const player = this.player;
      const TOLERANCE = 10000;
      if (Math.abs((currentTime - (player._lastCheckTimeUpdate || 0))) > TOLERANCE / 10) {

        const deltaTime = currentTime - start;

        // if we drift more than tolerance or the end time of this item has past,
        // force the re-evaluation of the player
        if ((currentTime > end ||  Math.abs(deltaTime - (currentTime * 1000)) > TOLERANCE)) {
          // calling this should load at the appropriate spot
          this.render();
        }

        this.player._lastCheckTimeUpdate = currentTime;
      }
    })

    this.player.on("ended", () => {
      if (!this.isSessionLive()) {
        this.setState({
          isLive: false,
          hasStarted: false,
        });

        this.player.dispose();
      }
    });

  }).catch((err) => {
    this.setState({
      error: true,
      errorMsg: 'Player failed to load.'
    })
    console.error(err);
  });
}

export default compose(withStore)(Player)
