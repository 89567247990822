import React from "react";
import { withRouter } from "react-router-dom";
import "./HomePage.scss";
import { compose } from "react-apollo";
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import Loading from "../../components/Loading/Loading";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import AppDownloads from "../../components/AppDownloads/AppDownloads";
import CategorizedSearch from "../../components/CategorizedSearch/CategorizedSearch";
import Helmet from "react-helmet";
import { getClientApiURL } from "../../utils"
import ScreenParser from "../../parser/ScreenParser";
import ServiceMessages from "../../components/ServiceMessages/ServiceMessages";

const HomePage: React.FunctionComponent<any> = ({ store: { language } }) => {
  return (
    <Fetch
      url={getClientApiURL(process.env.REACT_APP_HOMEPAGE_URL)}
      addMiddlewareHeaders
      options={{ credentials: "include" }}
      authzBeforeRequest
      >
      {(loading, errors, data) => {
        let screen = ScreenParser((data && data.screen) || {}, language);
        let serviceMessages = (data && data.serviceMessages) || [];

        return (
          errors ?
          <ErrorOverlay /> :
          <div className="HomePage">
            { loading && <Loading /> }
            <Helmet>
              <title>{screen.title}</title>
            </Helmet>
            { serviceMessages && <ServiceMessages messages={serviceMessages} /> }
            { data &&
              <React.Fragment>
                {
                  screen.components.map((component, index) => {
                    if (component.props.componentType === "HERO") {
                      return component;
                    }
                    return false;
                  })
                }
                <div className="content">
                  <div className="Intro-and-search">
                    <AppDownloads />
                    <CategorizedSearch />
                  </div>
                  {
                    screen.components.map((component, index) => {
                      if (component.props.componentType !== "HERO") {
                        return component;
                      }
                      return false;
                    })
                  }
                </div>
              </React.Fragment>
            }
          </div>
        )
      }}
    </Fetch>
  )
};

export default compose(withRouter, withStore)(HomePage);
