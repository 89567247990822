import React from "react";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import { SVGImage } from "../../assets/images";
import PersonParser from "../../parser/PersonParser";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import Loading from "../../components/Loading/Loading";
import {getClientApiURL } from "../../utils"
import "./PersonPage.scss";

const PersonPage: React.FunctionComponent<any> = ({ store: { language }, history }) => {
  const url = `${getClientApiURL(window.location.pathname)}`;

  return (
    <Fetch
      url={url}
      addMiddlewareHeaders
      options={{ credentials: "include" }}
      authzBeforeRequest
      >
      {(loading, errors, data) => {
        let screen = PersonParser(data || {});
        document.title = screen.title;

        return (
          errors ?
            <ErrorOverlay /> :
            <div className="PersonPage">
              <div className="back-btn" onClick={() => history.goBack()}><img src={SVGImage.BackArrow} alt="Back Arrow Icon" />Back</div>
              { loading && <Loading /> }
              { data && screen.components }
            </div>
        )
      }}
    </Fetch>
  )
};

export default compose(withStore)(PersonPage);
