import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { withStore } from "../../store";
import Tile from "../Tile/Tile";
import PersonTile from "../Tile/PersonTile";
import { Button } from "@brightcove/studio-components";
import classnames from "classnames";
import { toggleAccordion, getPageType } from "../../utils";
import "./Grid.scss";

class Grid extends React.Component<any, any> {

  static defaultProps = {
    pageSize: 3
  }

  constructor(props) {
    super(props);
    this.state = {
      visible : this.props.pageSize,
      expandGrid: this.props.expandGrid
    };
  }

  toggleGrid = () => {
    this.setState((prev) => {
      return {expandGrid: !prev.expandGrid};
    });
    this.props.onExpandGrid && this.props.onExpandGrid(this.props.header, this.state.expandGrid);
  }

  loadMore = () => {
    this.setState((prev) => {
      return {visible: prev.visible + this.props.pageSize};
    });
  }


  render() {
    const { tiles, header, videoCount, audioCount, pageSize, showPagination } = this.props;
    const { expandGrid, visible } = this.state;
    const pageType = getPageType();
    const tilesToShow = showPagination ? tiles.slice(0, visible) : tiles;
    const className = classnames("Grid accordion", typeof this.props.expandGrid !== "undefined" ? "accordion-desktop" : "", typeof expandGrid !== "undefined" ? (expandGrid ? "expanded" : "collapsed") : "");

    return (
      <div className={className}>
        <div className="Grid-header-wrapper" onClick={pageType === "performance" ? toggleAccordion : this.toggleGrid}>
          <div className="Grid-header">
            <h2>{header}<span className="Grid-items-count"> ({tiles.length})</span></h2>
            {typeof this.props.expandGrid !== "undefined" &&
              <p className="Grid-header-media-type-counts">
                {typeof videoCount !== undefined && videoCount > 0 &&
                  <span><strong className="count">{videoCount}</strong> Video&nbsp;&nbsp;</span>
                }
                {typeof audioCount !== undefined && audioCount > 0 &&
                  <span><strong className="count">{audioCount}</strong> Audio</span>
                }
              </p>
            }
          </div>
          <div className="Grid-header-accordion"><span></span></div>
        </div>
        <div className="Grid-contents">
          {
            tilesToShow.map((tile, index) => {
              return tile.roles ? <PersonTile key={index} {...tile} /> : <Tile key={index} {...tile} />
            })
          }
        </div>
        { (visible < tiles.length) && showPagination &&
          <Button look='tertiary' theme="classic" className="btn btn-clear btn-load-more" onClick={this.loadMore}>+ Load More ({pageSize})</Button>
        }
      </div>
    )
  }
};

export default compose(withRouter, withStore)(Grid);
