import { CHANGE_LANGUAGE, SHOW_LOG_IN, HIDE_LOG_IN, CLEAR, SET_USER, FETCH_USER, HANDLE_LOGOUT, TOGGLE_MAINTENANCE, SET_DYNAMIC_STRINGS } from "../actions";
import { StoreState, GenericAction, StorageField, storageKey } from "../types";
import _ from "lodash"

function updateLocalStorage(key: StorageField, value: any) {
  localStorage.setItem(storageKey(key), typeof value == "object" ? JSON.stringify(value) : value);
}

function updateWindowUser(user) {
  (window as any).user = user;
}

function clearLocalStorage() {
  for(let field in StorageField) {
    localStorage.removeItem(storageKey(field));
  }
}

export function initLocalStorage(values) {
  for(let field in StorageField) {
    let key = field.toLowerCase();
    let keyInStorage = storageKey(field);
    let valueInStorage = localStorage.getItem(keyInStorage);

    if(key in values && !valueInStorage) {
      updateLocalStorage(StorageField[StorageField[field]], values[key]);
    }
  }
}

export const initialState: StoreState = {
  language: "en",
  user: null,
  userFetchPending: false,
  displayLogIn: false,
  inMaintenance: false,
  maintenanceMessage: undefined,
  dynamicStrings: {},
};

export function reducer(state: StoreState = initialState, action: GenericAction) {
  switch(action.type) {

    case CHANGE_LANGUAGE:
      updateLocalStorage(StorageField.Language, action.data.language);
      return { ...state, language: action.data.language };

    case SHOW_LOG_IN:
      return { ...state, displayLogIn: true };

    case HIDE_LOG_IN:
      return { ...state, displayLogIn: false };

    case CLEAR:
      clearLocalStorage();
      return {...initialState, isAuthenticated: state.isAuthenticated};

    case SET_USER:
      // user_info returned a logged in user
      if (_.get(action, "data.user.extId")) {
        updateLocalStorage(StorageField.LoggedIn, true);
        updateWindowUser(action.data.user);
        return {...state, user: action.data.user, displayLogIn: false, userFetchPending: false};
      } else {
        updateLocalStorage(StorageField.LoggedIn, false);
        updateWindowUser(undefined);
        return {...state, user: undefined, userFetchPending: false, displayLogIn: false};
      }


    case FETCH_USER:
      return {...state, userFetchPending: true };

    case HANDLE_LOGOUT:
      updateLocalStorage(StorageField.LoggedIn, false);
      updateWindowUser(undefined);
      window.location.reload();
      return {...state, user: null};

    case TOGGLE_MAINTENANCE:
      return { ...state, inMaintenance: action.data.isActive, maintenanceMessage: action.data.message };

    case SET_DYNAMIC_STRINGS:
      return { ...state, dynamicStrings: {...action.data} }

    default:
      return state;
  }
};
