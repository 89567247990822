import React from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { compose } from "react-apollo";
import { initialize } from '@brightcove/studio-components';
import createStore, {withStore} from "./store";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import Loading from "./components/Loading/Loading";
import HomePage from "./routes/HomePage/HomePage";
import SearchPage from "./routes/SearchPage/SearchPage";
import PersonPage from "./routes/PersonPage/PersonPage";
import PerformancePage from "./routes/PerformancePage/PerformancePage";
import BroadcastPage from "./routes/BroadcastPage/BroadcastPage";
import AudioBroadcastPage from "./routes/AudioBroadcastPage/AudioBroadcastPage";
import BeforeYouProceed from "./routes/BeforeYouProceed/BeforeYouProceed";
import UserLimitPage from "./routes/UserLimitPage/UserLimitPage";
import PageNotFound from "./routes/PageNotFound/PageNotFound";
import MaintenanceOverlay from "./components/MaintenanceOverlay/MaintenanceOverlay";
import { fetchHandler, getDynamicStrings, getClientApiURL } from "./utils";

initialize();

const createdStore = createStore({});

class AppWrapper extends React.Component<any, any> {
  componentDidMount() {
    // const { store: { user } } = this.props;
    // const loggedIn = localStorage.getItem("MetOpera.LoggedIn");
    // if (loggedIn === "true" && !user) {
      this.getuserInfo();
    // }
    this.getDynamicStrings();
  }

  onSuccess = (response) => {
    this.props.setUser(response);
  }

  getuserInfo = () => {
    const url = `${getClientApiURL("/user_info")}`;
    this.props.fetchUser();
    fetchHandler(url, { headers: { "user-platform": "Web", "Cache-Control": "no-store" }}, this.onSuccess);
  }

  getDynamicStrings = () => {
    const url = `${getClientApiURL("/dynamic_strings")}`;
    fetchHandler(url, { headers: { "user-platform": "Web", "Cache-Control": "no-store" }}, this.onDynamicStringsFetchSuccess, this.onDynamicStringsFetchError);
  }

  onDynamicStringsFetchSuccess = (data) => {
    const { setDynamicStrings } = this.props;

    if (data && data.dynamicStrings && data.dynamicStrings.nodes) {
      const dynamicStrings = getDynamicStrings(data.dynamicStrings.nodes);
      setDynamicStrings(dynamicStrings);
    }
  }

  onDynamicStringsFetchError = (error) => {
    console.error('Could not retrieve dynamic strings: ', error);
  }

  render() {
    document.title = process.env.REACT_APP_PAGE_TITLE || "Met Opera on Demand";
    // @ts-ignore
    const { store } = this.props;
    const user = store.user
    const isStudent = user && user.data && user.institutionName
    const appBodyClass = `app-body ${isStudent ? "student-user" : ""} ${user && user.hasSubscription ? "user" : ""}`
      return (
        <div className="app-layout">
          <Header/>
          <div className={appBodyClass}>
            {
              (user === null) ? <Loading/> :
              store.inMaintenance ?
                <MaintenanceOverlay message={store.maintenanceMessage} /> :
              store.displayLogIn ?
              <Login /> :
              <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/search" exact component={SearchPage} />
                <Route path="/person/:id" exact component={PersonPage} />
                <Route path="/performance/detail/:id" exact component={PerformancePage} />
                <Route path="/broadcast/:id" exact component={BroadcastPage} />
                <Route path="/audio_broadcast/:id" exact component={AudioBroadcastPage} />
                <Route path="/user_limit" exact component={UserLimitPage} />
                <Route path="/leaving_student_access" exact component={BeforeYouProceed} />
                <Route component={PageNotFound} />
              </Switch>
            }
          </div>
          {!store.displayLogIn ? <Footer /> : null}
        </div>
      );
    }
}

const AppBody = compose(withRouter, withStore)(AppWrapper);

class App extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop>
            <ReduxProvider store={createdStore}>
              <AppBody />
            </ReduxProvider>
          </ScrollToTop>
        </Router>
      </React.Fragment>
    )
  }
}

export default App;
