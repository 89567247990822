import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import jwt_decode from "jwt-decode";
import { withStore } from "../../store";
import { SVG, SVGImage } from "../../assets/images";
import { Button, InputGroup, PlainInput } from "@brightcove/studio-components";
import ExternalLink from "../ExternalLink/ExternalLink";
import { fetchHandler, getClientApiURL } from "../../utils";
import "./Login.scss";
import _ from "lodash";
import { DESKTOP_MARGIN } from "../../utils/constants";

class Login extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    history.push(location.pathname);
    window.addEventListener('popstate', this.onPopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState);
  }

  onPopState = () => {
    this.props.hideLogIn();
  }

  onChange = (field) => {
    return (value) => {
      this.setState({
        [field]: value
      });
    }
  }

  focusOnInput = (inputName) => {
    const input = document.querySelector(`input[name="${inputName}"]`);
    if (input) {
      input.focus();
      window.scrollTo({top: input.offsetTop - DESKTOP_MARGIN * 2, behavior: "smooth"});
    }
  }

  onSuccess = (jwt) => {
    const { setUser } = this.props;
    const response = jwt_decode(jwt);
    setUser(response);
  }

  onError = (error) => {
    //if (error && error.)
    const errors = {
      auth: true,
    };
    this.setState({
      errors: errors,
    });
  }

  onSubmit = (e) => {
    const { username, password } = this.state,
      loginUrl = getClientApiURL("/login"),
      options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password })
      },
      errors = {};
    e.preventDefault();

    errors["username"] = _.isEmpty(username);
    errors["password"] = _.isEmpty(password);

    if(!errors["username"] && !errors["password"]) {
      fetchHandler(loginUrl, options, this.onSuccess, this.onError);
    }
    else {
      this.setState({
        errors: errors,
      });
    }
  }

  render() {
    const { username, password, errors } = this.state;
    const { hideLogIn } = this.props;
    return (
      <div className="Login">
        <div className="Login-close" onClick={hideLogIn}><img src={SVGImage.CloseThinIcon} alt="Close Icon" /></div>
        <div className="Login-body">
          <div className="Login-form">
            <form onSubmit={this.onSubmit}>
              <h1 className="Login-header">Login</h1>
              <h3>Returning Customers</h3>
              {(errors.auth || errors.username || errors.password) &&
                <div className="error-message-container">
                  {errors.auth && <p className="error-message">Sorry! We were unable to log you in with the Username and Password provided. If this is your first time here, please register now. Otherwise, please try again.</p>}
                  {(errors.username || errors.password) && <p className="error-message">Please fix the following error(s) on the form. Selecting one of the following link(s) will move the page to the form error.</p>}
                  <ol>
                    {errors.username && <li className="error-step">Please correct errors with your <span onClick={() => this.focusOnInput("username")}>email or username</span></li>}
                    {errors.password && <li className="error-step">Please correct errors with your <span onClick={() => this.focusOnInput("password")}>password</span></li>}
                  </ol>
                </div>
              }
              <div className={`form-field ${errors.username ? "form-field-error" : ""}`}>
                <InputGroup
                  label="Email or Username"
                  contentRight={<span>Required</span>}
                  error={errors.username && <span className="form-field-error-message"><SVG.Alert className="form-field-error-alert" />Email or Username is a required field</span>}
                >
                  <PlainInput
                    name="username"
                    placeholder="janedoe@example.com or janedoe"
                    value={username}
                    onChange={this.onChange("username")}
                  />
                </InputGroup>
              </div>
              <div className={`form-field ${errors.password ? "form-field-error" : ""}`}>
                <InputGroup
                  label="Password"
                  contentRight={<span>Required</span>}
                  error={errors.password && <span className="form-field-error-message"><SVG.Alert className="form-field-error-alert" />Password is a required field</span>}
                >
                  <PlainInput
                    name="password"
                    type="password"
                    placeholder="Type your password"
                    value={password}
                    onChange={this.onChange("password")}
                  />
                </InputGroup>
              </div>
              <div className="form-field">
                <Button type='submit' theme="classic" className="btn btn-red">Sign In</Button>
              </div>
              <div className="form-field forgot-password">
                <ExternalLink url="https://www.metopera.org/account/forgot-password/" text="Forgot Username or Password?" />
              </div>
              <div className="Login-disclaimer">
                <p>By signing in you agree to our <ExternalLink url="https://www.metopera.org/user-information/terms-and-conditions/" text="Terms of Service" /> and acknowledge our <ExternalLink url="https://www.metopera.org/user-information/privacy-policy/" text="Privacy Policy" />.</p>
              </div>
            </form>
          </div>
          <div className="Login-register">
            <h3>New Customers</h3>
            <p>Create an account to receive special ticket offers, information about events, or sign up to receive email communications.</p>
            <Button theme="classic" Component="a" componentProps={{href: "https://www.metopera.org/account/register/", target: "_blank"}} text="Register" className="btn btn-red" />
          </div>
        </div>
      </div>
    )
  }

};

export default compose(withRouter, withStore)(Login);
