import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { flowRight as compose, find as _find } from 'lodash';
import { withStore } from "../../store";
import SearchInput from "../../components/SearchInput/SearchInput";
import "./CategorizedSearch.scss";
import Fetch from "../../components/Fetch/Fetch";
import Loading from "../../components/Loading/Loading";
import { getClientApiURL } from "../../utils"

class CategorizedSearch extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      query: undefined
    }
  }

  onSearch = (query) => {
    this.setState({ query })
  }

  clearSearch = () => {
    this.setState({ query: undefined })
  }

  render() {
    const { query } = this.state;
    const url = query && getClientApiURL(`/search/${query}?brief`);

    return (
      <Fetch
        url={url}
        addMiddlewareHeaders
        options={{ credentials: "include" }}
        authzBeforeRequest
        >
        {(loading, errors, data) => {
          return (
            <div className="CategorizedSearch">
              <SearchInput autoFoucs={true} onChange={this.onSearch} onClearSearch={this.clearSearch} error={!loading && query && !data} />
              <ul className="search-results">
              { loading && <Loading inline /> }
              {
                query && data && data.reduce((reduced, current) => {
                  if(!_find(reduced, {type: current.type, name: current.name})) {
                    reduced.push(current)
                  }
                  return reduced
              } ,[]).map(({type, name}, index) => {
                  const searchUrl = `search?q=${name}`;
                  return <li key={index}><NavLink to={searchUrl}><span className="search-result-type">{type}</span><span>{name}</span></NavLink></li>
                })
              }
              {
                query && data && data.length === 0 &&
                  <li className="no-results">Exact match not found. Scroll down page to view complete catalog.</li>
              }
              </ul>
              <div className="content-advisory">
                <h5>Content Advisory</h5>
                <p>Some performances available in the Met Opera on Demand catalogue include offensive racial and cultural depictions and stereotypes. The issues are varied—from offensive past production practices such as blackface, brownface, and yellowface makeup to racist cultural depictions within the texts of the operas themselves. We continue to make these performances available because we believe they constitute an important part of the company’s artistic legacy. The Met is committed to addressing these vital issues in our programming, whether archival or in the future.</p>
              </div>
            </div>
          )
        }}
      </Fetch>
    )
  }
};

export default compose(withRouter, withStore)(CategorizedSearch);
