import React from "react";
import ExternalLink from "../ExternalLink/ExternalLink";
import { SVGImage, Image } from "../../assets/images";
import "./Footer.scss";

const LinkItem: React.FunctionComponent<any> = ({ url, text, image, alt, studentBypass }) => {
  return <li><ExternalLink url={url} text={text} image={image} alt={alt} studentBypass={studentBypass} /></li>
};

const Footer: React.FunctionComponent<any> = () => {
  return (
    <div className="Footer">
      <div className="Footer-content-wrapper">
        <div className="Footer-social">
          <p><ExternalLink className="email-signup" url="https://www.metopera.org/account/email-preferences/" text="Email Signup" /></p>
          <ul>
            <LinkItem url="https://www.facebook.com/MetOpera" alt="Facebook Icon" studentBypass={true} image={SVGImage.Facebook} />
            <LinkItem url="https://twitter.com/metopera" alt="Twitter Icon" studentBypass={true} image={SVGImage.Twitter} />
            <LinkItem url="https://www.instagram.com/metopera" alt="Instagram Icon" studentBypass={true} image={SVGImage.Instagram} />
            <LinkItem url="https://www.youtube.com/user/MetropolitanOpera" alt="Youtube Icon" studentBypass={true} image={SVGImage.Youtube} />
            <LinkItem url="https://www.tiktok.com/@metopera" alt="TikTok Icon" studentBypass={true} image={SVGImage.TikTok} />
          </ul>
        </div>
        <div className="Footer-divider"></div>
        <div className="Footer-legal">
          <ul>
            <LinkItem url="https://www.metopera.org/user-information/terms-and-conditions/" text="Terms & Conditions" />
            <LinkItem url="https://www.metopera.org/user-information/privacy-policy/" text="Privacy Policy" />
            <LinkItem url="https://www.metopera.org/user-information/privacy-policy/#ourads" text="Our Digital Ads" />
            <LinkItem url="https://www.metopera.org/japanese/" text="日本語" />
            <LinkItem url="https://www.metopera.org/visit/dining-at-the-met/" text="Dining" />
            <LinkItem url="https://www.metopera.org/visit/accessibility/" text="Accessibility" />
          </ul>
          <p>©{new Date().getFullYear()} The Metropolitan Opera</p>
        </div>
        <div className="Footer-divider"></div>
        <div className="Footer-sponsors">
          <ul>
            <LinkItem url="http://www.siriusxm.com/metropolitanopera" alt="Sirius XM" image={Image.Sirius} />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer;
