import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import SlideInfo from "../SlideInfo/SlideInfo";
import "./Slide.scss";

class Slide extends React.Component<any, any> {

  render() {
    const { image, index, zIndex } = this.props;
    return (
      <div className={`Slide ${index === 0 ? "info-active" : ""}`} data-slide-index={index} style={{zIndex: zIndex}}>
        <div className="Slide-overlay-image">
          <img src={image} alt="Hero" />
        </div>
        <SlideInfo {...this.props} />
      </div>
    )
  }
};

export default compose(withRouter)(Slide);

