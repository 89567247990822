import React from "react";
import { Button } from "@brightcove/studio-components";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import "./UserLimitPage.scss";

const UserLimitPage: React.FunctionComponent<any> = ({ store: { language }, history }) => {

  return (
    <div className="UserLimitPage">
        <h2>User Limit Notification</h2>
        <div className="rich-text"><p>Your university/college is limited to a specified number of simultaneous Authorized Users for playback of video and audio content available on Met Opera on Demand:&nbsp;<em>Student Access</em>.&nbsp; You may return to the catalog and try to play your selected performance again later.</p>
          <p>For additional assistance, please contact your library support staff or email the Met at&nbsp;<a title="Email the Met" href="mailto:studentaccessemail@metopera.org">studentaccessemail@metopera.org</a>.&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <Button theme="classic" Component="a" componentProps={{href: "/"}} className="btn btn-red">Back to Catalog</Button>
        </div>
    </div>
  )
};

export default compose(withStore)(UserLimitPage);
